import {
  AriaRole,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
} from "react";
import styled from "styled-components";
import variant from "sc-variant";
import {
  fontSize14,
  fontSize16,
  fontWeightSofiaProBold,
  radiusNormal,
  semanticAlertRedBgWeakest,
  semanticAlertRedIcon,
  semanticInfoBlueBgWeakest,
  semanticInfoBlueIcon,
  semanticSuccessGreenBgWeakest,
  semanticSuccessGreenIcon,
  semanticTextNormal,
  semanticWarningYellowBgWeakest,
  semanticWarningYellowIcon,
  spacingMedium,
} from "@tokens";

export interface AlertProps {
  /**
   * What kind of message does this alert transport?
   * @default error
   */
  variant?: "information" | "success" | "warning" | "error";
  /**
   * The title of the alert
   */
  title?: string;
  /**
   * Optional Icon
   */
  icon?: ReactNode;
  /**
   * Actions to be shown at the right side of the alert
   */
  action?: ReactNode;
  /**
   * @deprecated use action prop instead
   */
  close?: ReactNode;
  /**
   * Set `false` to disable role attribute
   * @default alert
   */
  role?: false | AriaRole;
}

type AlertCompProps = {
  variant: NonNullable<AlertProps["variant"]>;
  hasClose: boolean;
};
const AlertComp = styled.div<AlertCompProps>`
  color: ${semanticTextNormal};
  padding: ${spacingMedium};
  font-size: ${fontSize14};
  padding-right: ${({ hasClose }) => (hasClose ? "1.3rem" : "2rem")};
  border-radius: ${radiusNormal};
  display: flex;
  align-items: center;
  background-color: ${variant(
    "variant",
    {
      information: semanticInfoBlueBgWeakest,
      success: semanticSuccessGreenBgWeakest,
      warning: semanticWarningYellowBgWeakest,
      error: semanticAlertRedBgWeakest,
    },
    true,
  )};
`;

const Grow = styled.span`
  flex-grow: 1;
`;

const IconWrap = styled.div<{ variant: NonNullable<AlertProps["variant"]> }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacingMedium};
  color: ${variant(
    "variant",
    {
      information: semanticInfoBlueIcon,
      success: semanticSuccessGreenIcon,
      warning: semanticWarningYellowIcon,
      error: semanticAlertRedIcon,
    },
    true,
  )};

  svg {
    height: 1.5rem;
  }
`;

const TitleWrap = styled.div`
  font-weight: ${fontWeightSofiaProBold};
  font-size: ${fontSize16};
`;

const AlertActionWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spacingMedium};
  gap: ${spacingMedium};
`;

const Alert = forwardRef<
  HTMLDivElement,
  AlertProps & Omit<ComponentPropsWithoutRef<"div">, "title" | "role">
>(
  (
    {
      children,
      icon,
      title,
      close,
      action: userAction,
      variant = "error",
      role = "alert",
      ...props
    },
    ref,
  ) => {
    const action = userAction || close;
    return (
      <AlertComp
        variant={variant}
        hasClose={Boolean(close)}
        ref={ref}
        role={role || undefined}
        {...props}
      >
        {icon ? <IconWrap variant={variant}>{icon}</IconWrap> : null}
        <div>
          {title ? <TitleWrap>{title}</TitleWrap> : null}
          {children}
        </div>
        <Grow />
        {action ? <AlertActionWrap>{action}</AlertActionWrap> : null}
      </AlertComp>
    );
  },
);

Alert.displayName = "Alert";

export default Alert;
