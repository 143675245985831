import styled from "styled-components";
import {
  semanticAlertRedIcon,
  semanticAlertRedText,
  semanticSuccessGreenIcon,
  semanticSuccessGreenText,
} from "@tokens";

export interface ValidationMessageProps {
  type: "success" | "error";
}

export const ValidationMessage = styled.span.withConfig({
  shouldForwardProp: (prop) => !["type"].includes(prop),
})<ValidationMessageProps>`
  display: inline-flex;
  align-items: center;
  height: 2rem;
  font-size: 0.875rem;
  color: ${({ type }) =>
    type === "error" ? semanticAlertRedText : semanticSuccessGreenText};

  svg:first-of-type {
    color: ${({ type }) =>
      type === "error" ? semanticAlertRedIcon : semanticSuccessGreenIcon};
    height: 1.5rem;
    margin: 0.25rem 0.5rem 0.25rem 0;
  }
`;
