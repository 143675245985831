import type { SwitchProps as RadixSwitchProps } from "@radix-ui/react-switch";
import { ReactNode } from "react";
import { Label, ToggleRoot, ToggleThumb } from "./Blocks.Toggle";

export type CheckboxVariant = "primary" | "secondary";
export type Side = "left" | "right";
export interface ToggleProps extends RadixSwitchProps {
  /**
   * The label for the toggle
   */
  children?: ReactNode;
  variant?: CheckboxVariant;
  /**
   * The side of the toggle the label should be on
   */
  side?: Side;
}

const Toggle = ({
  children,
  defaultChecked,
  checked,
  onCheckedChange,
  variant = "primary",
  side = "left",
  ...props
}: ToggleProps) => {
  return (
    <Label side={side}>
      {children}
      <ToggleRoot
        variant={variant}
        defaultChecked={defaultChecked}
        checked={checked}
        onCheckedChange={onCheckedChange}
        {...props}
      >
        <ToggleThumb />
      </ToggleRoot>
    </Label>
  );
};

Toggle.displayName = "Toggle";

export default Toggle;
