export const THEME_MODES = [
  "system",
  "system-inverted",
  "light",
  "dark",
] as const;

export type ThemeMode = (typeof THEME_MODES)[number];

export const THEME_INVERSIONS: Record<ThemeMode, ThemeMode> = {
  system: "system-inverted",
  "system-inverted": "system",
  light: "dark",
  dark: "light",
};
