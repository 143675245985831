import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const EditIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="m19.97 6.92-4.49-4.49a.41.41 0 0 0-.58 0l-9.73 9.73a.36.36 0 0 0-.11.21L4.02 17.9c-.05.28.19.53.48.48l5.53-1.04c.08-.02.15-.05.21-.11l9.73-9.73a.41.41 0 0 0 0-.58Zm-6.26 3.54L9.1 15.07a.36.36 0 0 1-.21.11l-1.48.28a.413.413 0 0 1-.48-.48l.28-1.48c.02-.08.05-.15.11-.21l4.61-4.61a.41.41 0 0 1 .58 0l1.2 1.2c.16.16.16.42 0 .58Zm2.96-2.96-.74.74a.41.41 0 0 1-.58 0l-1.2-1.2a.41.41 0 0 1 0-.58l.74-.74a.41.41 0 0 1 .58 0l1.2 1.2c.16.16.16.42 0 .58ZM14.85 19.36H4.02a.41.41 0 0 0-.41.41v1.51c0 .226.184.41.41.41h10.83a.41.41 0 0 0 .41-.41v-1.51a.41.41 0 0 0-.41-.41ZM19.98 19.36h-3a.41.41 0 0 0-.41.41v1.51c0 .226.184.41.41.41h3a.41.41 0 0 0 .41-.41v-1.51a.41.41 0 0 0-.41-.41Z"
    />
  </Icon>
));

EditIcon.displayName = "EditIcon";
