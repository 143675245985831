import { css } from "styled-components";
import {
  fontSize14,
  fontSize18,
  fontSize20,
  fontSize24,
  fontSize32,
  fontSize48,
  fontSize64,
  fontSize80,
  fontWeightSofiaProBold,
  fontWeightSofiaProLight,
  fontWeightSofiaProRegular,
  letterSpacingSpaced,
  lineHeight20,
  lineHeight26,
  lineHeight28,
  lineHeight32,
  lineHeight40,
  lineHeight48,
  lineHeight64,
  lineHeight80,
  maxSmall,
  minMedium,
  semanticTextNormal,
  spacingMedium,
} from "@tokens";

const noBottomMargin = css`
  margin-bottom: 0;
`;

export const sectionHeaderStyles = css<{
  margin?: boolean;
}>`
  ${({ margin = true }) => !margin && noBottomMargin}
  font-size: ${fontSize14};
  line-height: ${lineHeight20};
  letter-spacing: ${letterSpacingSpaced};
  font-weight: ${fontWeightSofiaProBold};
  text-transform: uppercase;
`;

const headlineMargin = css`
  margin: 0 0 ${spacingMedium};
`;

export const commonHeadingStyles = css<{
  margin?: boolean;
}>`
  ${({ margin = true }) => (!margin ? noBottomMargin : headlineMargin)}
  font-weight: ${fontWeightSofiaProLight};
  color: ${semanticTextNormal};
`;

const mainHeadingOnSmallScreenStyles = css`
  font-size: ${fontSize32};
  line-height: ${lineHeight40};
`;

export const display1Styles = css`
  ${commonHeadingStyles}
  ${mainHeadingOnSmallScreenStyles}

  @media ${minMedium} {
    font-size: max(${fontSize32}, min(6.67vw, ${fontSize80}));
    line-height: max(${lineHeight40}, min(6.67vw, ${lineHeight80}));
  }
`;

export const display2Styles = css`
  ${commonHeadingStyles}
  ${mainHeadingOnSmallScreenStyles}

  @media ${minMedium} {
    font-size: max(${fontSize32}, min(5.335vw, ${fontSize64}));
    line-height: max(${lineHeight40}, min(5.335vw, ${lineHeight64}));
  }
`;

export const h1Styles = css`
  ${commonHeadingStyles}
  ${mainHeadingOnSmallScreenStyles}

  @media ${minMedium} {
    font-size: max(${fontSize32}, min(4vw, ${fontSize48}));
    line-height: max(${lineHeight40}, min(4vw, ${lineHeight48}));
  }
`;

export const h2Styles = css`
  ${commonHeadingStyles}
  font-size: ${fontSize24};
  line-height: ${lineHeight32};

  @media ${minMedium} {
    font-size: max(${fontSize24}, min(2.667vw, ${fontSize32}));
    line-height: max(${lineHeight32}, min(3.334vw, ${lineHeight40}));
  }
`;

export const h3Styles = css`
  ${commonHeadingStyles}
  font-size: ${fontSize20};
  line-height: ${lineHeight28};

  @media ${maxSmall} {
    font-weight: ${fontWeightSofiaProRegular};
  }

  @media ${minMedium} {
    font-size: max(${fontSize20}, min(2.667vw, ${fontSize24}));
    line-height: max(${lineHeight28}, min(3.334vw, ${lineHeight32}));
  }
`;

const smallHeadingOnSmallScreen = css`
  font-size: ${fontSize18};
  line-height: ${lineHeight26};
`;

export const h4Styles = css`
  ${commonHeadingStyles}
  ${smallHeadingOnSmallScreen}

  font-weight: ${fontWeightSofiaProRegular};

  @media ${minMedium} {
    font-size: max(${fontSize18}, min(1.667vw, ${fontSize20}));
    line-height: max(${lineHeight26}, min(2.334vw, ${lineHeight28}));
  }
`;

export const h5Styles = css`
  ${commonHeadingStyles}
  ${smallHeadingOnSmallScreen}

  font-weight: ${fontWeightSofiaProBold};
`;

export const h6Styles = css`
  ${commonHeadingStyles}
  ${smallHeadingOnSmallScreen}

  font-weight: ${fontWeightSofiaProRegular};
`;
