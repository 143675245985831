import styled, { css } from "styled-components";
import { InnerInput, InputBox, InputIcon, inputStyles } from "./Blocks.input";
import { semanticTextWeak } from "@tokens";
import { ComponentPropsWithoutRef, ReactNode, forwardRef, useRef } from "react";
import { mergeRefs } from "react-merge-refs";

export type IconAlignment = "left" | "right";

export interface CommonProps {
  hasError?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  emulateFocus?: boolean;
}

export type IconProps = {
  icon: ReactNode;
  iconAlignment?: IconAlignment;
} & CommonProps;

export type BaseProps = {
  icon?: never;
  iconAlignment?: never;
} & CommonProps;

export type InputProps = IconProps | BaseProps;

const inputMetaStyles = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`;

export const InputInfo = styled.div`
  ${inputMetaStyles}
  text-align: end;
  color: ${semanticTextWeak};
`;
export const InputFeedback = styled.div`
  ${inputMetaStyles}
`;

const InputWithIcon = forwardRef<
  HTMLInputElement,
  InputProps & ComponentPropsWithoutRef<"input">
>(
  (
    {
      disabled,
      hasError,
      fullWidth,
      icon,
      iconAlignment = "right",
      emulateFocus,
      className,
      style,
      ...inputProps
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
      <InputBox
        disabled={disabled}
        hasError={hasError}
        fullWidth={fullWidth}
        emulateFocus={emulateFocus}
        onClick={() => {
          inputRef.current?.focus();
        }}
        className={className}
        style={style}
      >
        <InnerInput
          ref={mergeRefs([ref, inputRef])}
          disabled={disabled}
          alignment={iconAlignment}
          {...inputProps}
        />
        <InputIcon alignment={iconAlignment}>{icon}</InputIcon>
      </InputBox>
    );
  },
);

const BaseInput = styled.input.withConfig({
  shouldForwardProp: (p) => !["hasError", "fullWidth"].includes(p),
})<InputProps>(() => inputStyles);

/*
Why do we have this indirection instead of just using InputWithIcon everywhere?

InputWithIcon has two nitty gritty different behaviors compared to BaseInput:
- clicking the border requires manual refocus with JS
- hovering the label does not highlight the input

Therefore we render the plain input when no icon is provided.
*/
export const Input = forwardRef<
  HTMLInputElement,
  InputProps & ComponentPropsWithoutRef<"input">
>(({ icon, ...inputProps }, ref) => {
  if (icon) {
    return <InputWithIcon {...inputProps} ref={ref} icon={icon} />;
  }
  const props = inputProps as BaseProps;
  return <BaseInput {...props} ref={ref} />;

  /* TypeCasting to support styled-components as={Comp} */
}) as typeof BaseInput;
