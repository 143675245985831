import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const BellIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="-1 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.334 15V7.85C2.334 3.973 5.318.832 9 .832c3.682 0 6.667 3.141 6.667 7.016V15h1.666v1.667H.667V15h1.667ZM9 2.5c2.683 0 5 2.314 5 5.35V15H4V7.85C4 4.813 6.318 2.5 9 2.5Z"
      fill="currentColor"
    />
    <path d="M5.667 17.5h6.666v1.667H5.667V17.5Z" fill="currentColor" />
  </Icon>
));

BellIcon.displayName = "BellIcon";
