/**
 * styled-component helpers for styles used across various components
 */

import { semanticBdrStrongest } from "@tokens";
import type { PropertiesHyphen } from "csstype";
import { css } from "styled-components";

export const visuallyHidden = css`
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`;

export const focusRing = css`
  outline: 2px solid ${semanticBdrStrongest};
  outline-offset: var(--outline-offset, 2px);
`;

export const focusVisible = css`
  &:focus-visible:not([disabled], [data-disabled]) {
    ${focusRing}
  }
`;

export const transition = (
  /** Array of css properties that should transition.
   * Will not transition on hover and focus-visible by default. */
  transitionProps: (keyof PropertiesHyphen)[],
  /** Array of css properties that should transition on hover or focus-visible
   * or `true` to not disable transitions.
   *
   * Default: `false` */
  interactiveTransitionProps: (keyof PropertiesHyphen)[] | boolean = false,
) => {
  return Object.assign(
    [
      `transition: ${transitionProps
        .map((prop) => `${prop} 0.3s ease`)
        .join(", ")};`,
      interactiveTransitionProps === true
        ? ""
        : `&:hover:not([disabled]):not([data-disabled]), &:focus-visible:not([disabled]):not([data-disabled]) { transition: ${
            interactiveTransitionProps === false
              ? "none"
              : interactiveTransitionProps
                  .map((prop) => `${prop} 0.3s ease`)
                  .join(", ")
          }; }`,
    ],
    { isCss: true },
  );
};
