import {
  radiusNormal,
  semanticAlertRedBg,
  semanticAlertRedBgWeakest,
  semanticGrayBgStrong,
  semanticGrayBgWeak,
  semanticInfoBlueBg,
  semanticInfoBlueBgWeakest,
  semanticPrimaryBg,
  semanticSecondaryBg,
  semanticSuccessGreenBg,
  semanticSuccessGreenBgWeakest,
  semanticTextNormal,
  semanticWarningYellowBg,
  semanticWarningYellowBgWeakest,
} from "@tokens";
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react";
import variant from "sc-variant";
import styled from "styled-components";

export interface ProgressIndicatorProps {
  /** the current percentage of progress, also see `max` prop */
  progress: number;
  /** what kind of progress is this? */
  variant?:
    | "alert"
    | "information"
    | "success"
    | "warning"
    | "neutral"
    | "primary"
    | "secondary";
  size?: "small" | "normal";
  title?: ReactNode;
  showPercent?: boolean;
  status?: ReactNode;
  /** what is the maximum progress that we expect? */
  max?: number;
}

type ProgressIndicatorCompProps = {
  variant: NonNullable<ProgressIndicatorProps["variant"]>;
  size: NonNullable<ProgressIndicatorProps["size"]>;
};

const ProgressIndicatorComp = styled.div<ProgressIndicatorCompProps>`
  overflow: auto;
  border-radius: ${radiusNormal};
  background-color: ${variant(
    "variant",
    {
      information: semanticInfoBlueBgWeakest,
      success: semanticSuccessGreenBgWeakest,
      warning: semanticWarningYellowBgWeakest,
      alert: semanticAlertRedBgWeakest,
      neutral: semanticGrayBgWeak,
      primary: semanticGrayBgWeak,
      secondary: semanticGrayBgWeak,
    },
    true,
  )};
  width: 100%;
  height: ${variant("size", { small: "0.25rem", normal: "0.5rem" })};
`;

type ProgressMeterProps = {
  variant: NonNullable<ProgressIndicatorProps["variant"]>;
  size: NonNullable<ProgressIndicatorProps["size"]>;
  percentage: number;
};

const ProgressMeter = styled.div<ProgressMeterProps>`
  background-color: ${variant(
    "variant",
    {
      information: semanticInfoBlueBg,
      success: semanticSuccessGreenBg,
      warning: semanticWarningYellowBg,
      alert: semanticAlertRedBg,
      neutral: semanticGrayBgStrong,
      primary: semanticPrimaryBg,
      secondary: semanticSecondaryBg,
    },
    true,
  )};
  border-radius: ${radiusNormal};
  width: ${({ percentage }) => `${percentage}%`};
  will-change: width;
  transition:
    width 0.2s linear,
    background-color 0.3s ease;
  height: ${variant("size", { small: "0.25rem", normal: "0.5rem" })};
`;

const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  color: ${semanticTextNormal};
`;

const Status = styled.div`
  display: flex;
  gap: 1rem;
`;

const Title = styled.div`
  word-break: break-word;
`;

const ProgressIndicator = forwardRef<
  HTMLDivElement,
  ProgressIndicatorProps &
    Omit<ComponentPropsWithoutRef<"div">, "title" | "role">
>(
  (
    {
      progress,
      variant = "information",
      size = "normal",
      title,
      showPercent = false,
      max = 100,
      status,
      ...rest
    },
    ref,
  ) => {
    const clampedProgress = Math.round(Math.max(Math.min(progress, max), 0));
    const percentage = Math.round((clampedProgress / max) * 100);
    return (
      <>
        {(title || showPercent || status) && (
          <ProgressInfo>
            <Title>{title}</Title>
            <Status>
              {status}
              {showPercent && <div>{percentage}%</div>}
            </Status>
          </ProgressInfo>
        )}
        <ProgressIndicatorComp
          ref={ref}
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={max}
          aria-valuenow={clampedProgress}
          aria-valuetext={`${percentage}% (${clampedProgress} of ${max} complete)`}
          variant={variant}
          size={size}
          {...rest}
        >
          <ProgressMeter
            variant={variant}
            percentage={percentage}
            size={size}
          />
        </ProgressIndicatorComp>
      </>
    );
  },
);

ProgressIndicator.displayName = "ProgressIndicator";

export default ProgressIndicator;
