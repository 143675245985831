import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Icon } from "./Icon";

export const SuccessOvalIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      ...props.style,
    }}
  >
    <path
      d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0ZM9.91 15.193 6.801 11.46a.75.75 0 0 0-1.153.96l3.675 4.413a.748.748 0 0 0 .219.18l.014.007a.753.753 0 0 0 .455.075l.09-.019a.744.744 0 0 0 .367-.233l7.544-8.745a.751.751 0 0 0-1.136-.98L9.91 15.193Z"
      fill="currentColor"
      transform="matrix(.83333 0 0 .83333 0 0)"
    />
  </Icon>
));

SuccessOvalIcon.displayName = "SuccessOvalIcon";
