import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const ChartLineIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth={1.5}
    stroke="currentColor"
    ref={ref}
    {...props}
  >
    <path
      d="M3 16.5L9 10L13 16L21 6.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
));

ChartLineIcon.displayName = "ChartLineIcon";
