import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const DeleteIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      d="M19.327 7.27697C19.5344 7.27697 19.7021 7.10922 19.7021 6.90181V5.50834C19.7021 5.30093 19.5344 5.13318 19.327 5.13318H4.67521C4.4678 5.13318 4.30005 5.30093 4.30005 5.50834V6.90181C4.30005 7.10922 4.4678 7.27697 4.67521 7.27697H5.39553V19.8562V22H18.6067V19.8562V7.27697H19.327ZM9.08446 19.8562H7.53879V7.27697H9.08446V19.8562ZM12.7739 19.8562H11.2283V7.27697H12.7739V19.8562ZM16.4634 19.8562H14.9177V7.27697H16.4634V19.8562Z"
      fill="currentColor"
    />
    <path
      d="M14.5475 3.76863L14.5475 2.37516C14.5475 2.16797 14.3795 2 14.1723 2L9.8038 2C9.5966 2 9.42864 2.16797 9.42864 2.37516L9.42864 3.76863C9.42864 3.97583 9.5966 4.14379 9.8038 4.14379L14.1723 4.14379C14.3795 4.1438 14.5475 3.97583 14.5475 3.76863Z"
      fill="currentColor"
    />
  </Icon>
));

DeleteIcon.displayName = "DeleteIcon";
