import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const MinusIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z"
      clipRule="evenodd"
    />
  </Icon>
));

MinusIcon.displayName = "MinusIcon";
