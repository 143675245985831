import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Icon } from "./Icon";

export const VideoIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
    ref={ref}
  >
    <path
      d="M10.11 9.33003L14.44 11.45C14.92 11.68 14.91 12.36 14.44 12.59L10.11 14.67C9.68995 14.87 9.19995 14.57 9.19995 14.1V9.90003C9.19995 9.43003 9.68995 9.13003 10.11 9.33003Z"
      fill="currentColor"
    />
    <path
      d="M22.84 19.3801H1.16001C0.94001 19.3801 0.76001 19.2001 0.76001 18.9801V5.02012C0.76001 4.80012 0.94001 4.62012 1.16001 4.62012H22.84C23.06 4.62012 23.24 4.80012 23.24 5.02012V18.9801C23.24 19.2001 23.06 19.3801 22.84 19.3801ZM3.47001 17.0801H20.53C20.75 17.0801 20.93 16.9001 20.93 16.6801V7.33012C20.93 7.11012 20.75 6.93012 20.53 6.93012H3.47001C3.25001 6.93012 3.07001 7.11012 3.07001 7.33012V16.6801C3.07001 16.9001 3.25001 17.0801 3.47001 17.0801Z"
      fill="currentColor"
    />
  </Icon>
));

VideoIcon.displayName = "VideoIcon";
