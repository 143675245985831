import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const CircleIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    strokeWidth="0"
    stroke="currentColor"
    ref={ref}
    {...props}
  >
    <circle cx="12" cy="12" r="12" />
  </Icon>
));

CircleIcon.displayName = "CircleIcon";
