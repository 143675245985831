import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const FileCsvIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    role="img"
    aria-label="FileCsvIcon"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.96139 1.00319C0.96139 0.449143 1.41053 0 1.96458 0H14.4626C14.7287 0 14.9839 0.105693 15.172 0.293827L20.9403 6.06216C21.1285 6.2503 21.2342 6.50546 21.2342 6.77153V8.69431H19.2278V7.18706L14.0471 2.00638H2.96777V8.69431H0.96139V1.00319ZM0 11.5785C0 11.0244 0.449143 10.5753 1.00319 10.5753H5.81014V12.5817H2.00638V16.3436H5.81014V18.35H1.00319C0.449143 18.35 0 17.9009 0 17.3468V11.5785ZM2.96777 21.1506V20.231H0.96139V22.1538C0.96139 22.7078 1.41053 23.1569 1.96458 23.1569H20.231C20.785 23.1569 21.2342 22.7078 21.2342 22.1538V20.231H19.2278V21.1506H2.96777Z"
      fill="currentColor"
    />
    <path
      d="M13.5012 1.00293V7.73266H20.2309L13.5012 1.00293Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7131 10.6168L17.3689 17.699C17.5158 18.0905 17.8901 18.3499 18.3082 18.3499C18.7264 18.3499 19.1007 18.0905 19.2476 17.699L21.9034 10.6168H19.7606L18.3082 14.4896L16.8559 10.6168H14.7131ZM8.69435 10.5752C8.14031 10.5752 7.69116 11.0243 7.69116 11.5784V14.4626C7.69116 15.0166 8.14031 15.4657 8.69435 15.4657H11.5367V16.3435H7.73296V18.3499H12.5399C13.094 18.3499 13.5431 17.9008 13.5431 17.3467V14.4626C13.5431 13.9085 13.094 13.4594 12.5399 13.4594H9.69754V12.5816H13.5013V10.5752H8.69435Z"
      fill="currentColor"
    />
  </Icon>
));

FileCsvIcon.displayName = "FileCsvIcon";
