import { ComponentPropsWithoutRef, forwardRef } from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

const MoreMenuIconSvg = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
`;

type MoreMenuOrientation = "horizontal" | "vertical";

export const MoreMenuIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg"> & {
    orientation?: MoreMenuOrientation;
  }
>(({ orientation = "horizontal", style, ...props }, ref) => {
  return (
    <>
      {orientation === "horizontal" ? (
        <MoreMenuIconSvg
          viewBox="0 0 22 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={ref}
          {...props}
        >
          <path
            d="M5.58462 0H0.415385C0.185974 0 0 0.185974 0 0.415384V5.58462C0 5.81403 0.185974 6 0.415385 6H5.58462C5.81403 6 6 5.81403 6 5.58462V0.415384C6 0.185974 5.81403 0 5.58462 0Z"
            fill="currentColor"
          />
          <path
            d="M13.5846 0H8.41538C8.18597 0 8 0.185974 8 0.415384V5.58462C8 5.81403 8.18597 6 8.41538 6H13.5846C13.814 6 14 5.81403 14 5.58462V0.415384C14 0.185974 13.814 0 13.5846 0Z"
            fill="currentColor"
          />
          <path
            d="M21.5846 0H16.4154C16.186 0 16 0.185974 16 0.415384V5.58462C16 5.81403 16.186 6 16.4154 6H21.5846C21.814 6 22 5.81403 22 5.58462V0.415384C22 0.185974 21.814 0 21.5846 0Z"
            fill="currentColor"
          />
        </MoreMenuIconSvg>
      ) : (
        <Icon
          viewBox="0 0 6 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={ref}
          {...props}
        >
          <path
            d="M4.61883 0.5H1.38117C0.894512 0.5 0.5 0.891751 0.5 1.375V4.625C0.5 5.10825 0.894512 5.5 1.38117 5.5H4.61883C5.10549 5.5 5.5 5.10825 5.5 4.625V1.375C5.5 0.891751 5.10549 0.5 4.61883 0.5Z"
            fill="currentColor"
          />
          <path
            d="M4.61883 8.5H1.38117C0.894512 8.5 0.5 8.89175 0.5 9.375V12.625C0.5 13.1082 0.894512 13.5 1.38117 13.5H4.61883C5.10549 13.5 5.5 13.1082 5.5 12.625V9.375C5.5 8.89175 5.10549 8.5 4.61883 8.5Z"
            fill="currentColor"
          />
          <path
            d="M4.61883 16.5H1.38117C0.894512 16.5 0.5 16.8918 0.5 17.375V20.625C0.5 21.1082 0.894512 21.5 1.38117 21.5H4.61883C5.10549 21.5 5.5 21.1082 5.5 20.625V17.375C5.5 16.8918 5.10549 16.5 4.61883 16.5Z"
            fill="currentColor"
          />
        </Icon>
      )}
    </>
  );
});
MoreMenuIcon.displayName = "MoreMenuIcon";
