import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const CalendarIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      d="M19.52 4.31h2.51c.22 0 .39.18.39.39v15.75c0 .22-.18.39-.39.39v.01H1.97a.39.39 0 0 1-.39-.39V4.7c0-.22.18-.39.39-.39h2.52v-.77a.39.39 0 0 1 .39-.39h1.48a.39.39 0 0 1 .39.39v.77h4.12v-.77a.39.39 0 0 1 .39-.39h1.48a.39.39 0 0 1 .39.39v.77h4.13v-.77a.39.39 0 0 1 .39-.39h1.48a.39.39 0 0 1 .39.39v.77ZM4.49 6.57h-.26a.39.39 0 0 0-.39.39V9.1h16.33V6.96a.39.39 0 0 0-.39-.39h-.26V7a.39.39 0 0 1-.39.39h-1.48a.39.39 0 0 1-.39-.39v-.43h-4.13V7a.39.39 0 0 1-.39.39h-1.48a.39.39 0 0 1-.39-.39v-.43H6.75V7a.39.39 0 0 1-.39.39H4.88A.39.39 0 0 1 4.49 7v-.43Zm-.65 4.79v6.84c0 .22.18.39.39.39h15.55c.22 0 .39-.18.39-.39v-6.84H3.84Zm13.78 1.6a.62.62 0 0 1 .62.62v2.29a.62.62 0 0 1-.62.62H6.2a.62.62 0 0 1-.62-.62v-2.29a.62.62 0 0 1 .62-.62h11.42Z"
      style={{
        fill: "currentColor",
      }}
    />
  </Icon>
));

CalendarIcon.displayName = "CalendarIcon";
