import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Icon } from "./Icon";

export const ErrorOvalIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      ...props.style,
    }}
  >
    <path
      d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0Zm0 15.619a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25Zm-1-9.347v6.751a1.001 1.001 0 0 0 2 0V6.272a1.001 1.001 0 0 0-2 0Z"
      fill="currentColor"
      transform="matrix(.83333 0 0 .83333 0 0)"
    />
  </Icon>
));

ErrorOvalIcon.displayName = "ErrorOvalIcon";
