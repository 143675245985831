import type { Theme } from "./Theme";
import { useEffect } from "react";
import { THEME_MODES, ThemeMode } from "./ThemeMode";

export function useThemeModeOnElement(
  mode: null | ThemeMode = "system",
  element: HTMLElement | null = null,
) {
  useEffect(() => {
    if (!element) {
      return;
    }

    for (const mode of THEME_MODES) {
      element.classList.remove(themeModeClassName({ mode }));
    }

    if (mode) {
      element.classList.add(themeModeClassName({ mode }));
    }
  }, [mode]);
}

export function themeModeClassName({
  className,
  mode,
}: {
  className?: string;
  mode?: ThemeMode;
}) {
  return [className, mode ? `${mode}-theme` : false].filter(Boolean).join(" ");
}

export function themeToCssVariables(theme: Record<string, string>) {
  if (!theme) {
    return "";
  }
  return Object.entries(theme)
    .filter(([_, v]) => Boolean(v))
    .map(([key, val]) => `--${key}: ${val};`)
    .join("\n");
}

export function mapTo<
  Obj extends Record<string, string>,
  TargetVal extends string,
>(input: Obj, targetVal: TargetVal) {
  return Object.fromEntries(Object.keys(input).map((k) => [k, targetVal])) as {
    [K in keyof Obj]: TargetVal;
  };
}

function mergeOverwrites<Modes extends NonNullable<Theme["extend"]>>(
  ...modes: Modes[]
) {
  return modes.reduce(
    (final, mode) =>
      ({
        dark: { ...final.dark, ...mode.dark },
        light: { ...final.light, ...mode.light },
        common: { ...final.common, ...mode.common },
      }) as Modes,
    {} as Modes,
  );
}

export function mergeThemes(...themes: Theme[]): Theme {
  return themes.reduce((final, theme) => {
    return {
      overwrites: [
        mergeOverwrites(
          ...(final.overwrites || []),
          ...(theme.overwrites || []),
        ),
      ],
      extend: mergeOverwrites(final.extend || {}, theme.extend || {}),
    };
  }, {});
}
