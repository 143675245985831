import { css } from "styled-components";
import {
  fontFamilyMonospace,
  fontFamilySofiaPro,
  fontSize12,
  fontSize14,
  fontSize16,
  fontWeightSofiaProBold,
  fontWeightSofiaProRegular,
  fontWeightSofiaProSemibold,
  letterSpacingNormal,
  lineHeight16,
  lineHeight20,
  lineHeight24,
  semanticBackgroundBase,
  semanticTextNormal,
  spacingMedium,
  spacingSmall,
} from "@tokens";

/**
 * Use this to apply the current theme text-color to an element
 */
export const textStyles = css`
  color: ${semanticTextNormal};
`;

/**
 * Use this to apply the current theme background-color to an element
 */
export const bgStyles = css`
  background-color: ${semanticBackgroundBase};
`;

export const bodyFontStyles = css`
  font-size: ${fontSize16};
  line-height: ${lineHeight24};
`;

/**
 * Use this to apply the current theme font-settings to an element
 */
export const documentFontStyles = css`
  font-family: ${fontFamilySofiaPro};
  font-weight: ${fontWeightSofiaProRegular};
  letter-spacing: ${letterSpacingNormal};
  ${bodyFontStyles}
`;

/* <html>, <body> */
export const documentStyles = css`
  ${textStyles}
  ${bgStyles}
  ${documentFontStyles}
  -webkit-tap-highlight-color: ${semanticBackgroundBase};
`;

/* <hr> */
const hrMargin = css`
  margin: ${spacingSmall} ${spacingMedium};
`;
export const hrStyles = css<{
  margin?: boolean;
}>`
  ${({ margin = true }) => margin && hrMargin};
  border: none;
  border-top: 1px solid ${semanticTextNormal};
`;

/* <strong> */
export const boldStyles = css`
  font-weight: ${fontWeightSofiaProBold};
`;
export const semiBoldStyles = css`
  font-weight: ${fontWeightSofiaProSemibold};
`;

/* <code> */
export const monoStyles = css`
  font-family: ${fontFamilyMonospace};
`;

/* <p> */
const noBottomMargin = css`
  margin-bottom: 0;
`;
export const paragraphStyles = css<{
  margin?: boolean;
}>`
  ${bodyFontStyles}
  ${textStyles}
  ${({ margin = true }) => !margin && noBottomMargin}
`;

/* <small> */
export const smallStyles = css`
  font-size: ${fontSize14};
  line-height: ${lineHeight20};
`;
export const xSmallStyles = css`
  font-size: ${fontSize12};
  line-height: ${lineHeight16};
`;
