import styled, { css } from "styled-components";
import {
  semanticBackgroundBase,
  semanticBdrNormal,
  shadowModalsOuterShadow,
} from "@tokens";

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  min-width: max-content;
  background-color: ${semanticBackgroundBase};
  border: 1px solid ${semanticBdrNormal};
  border-radius: 6px;
  box-shadow: ${shadowModalsOuterShadow};
  box-sizing: border-box;
  z-index: 100;

  &:focus {
    outline: none;
  }
`;

export const DropdownContent = styled.div(
  ({
    maxHeight,
    maxWidth,
  }: {
    maxHeight?: string | number;
    maxWidth?: string | number;
  }) => {
    const maxHeightVal =
      typeof maxHeight === "string" ? maxHeight : `${maxHeight}px`;
    const maxWidthVal =
      typeof maxWidth === "string" ? maxWidth : `${maxWidth}px`;
    return css`
      overflow-y: auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 4px;
      max-height: ${maxHeightVal};
      max-width: ${maxWidthVal};
    `;
  },
);
