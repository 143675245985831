import styled from "styled-components";
import {
  display1Styles,
  display2Styles,
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  h6Styles,
  sectionHeaderStyles,
} from "./headingStyles";

export const SectionHeader = styled.h4.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => sectionHeaderStyles);

export const Display1 = styled.h1.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => display1Styles);

export const Display2 = styled.h2.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => display2Styles);

export const H1 = styled.h1.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h1Styles);

export const H2 = styled.h2.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h2Styles);

export const H3 = styled.h3.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h3Styles);

export const H4 = styled.h4.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h4Styles);

export const H5 = styled.h5.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h5Styles);

export const H6 = styled.h6.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h6Styles);
