import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Icon } from "./Icon";

export const MenuIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="-1 -1 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.667 4a3.333 3.333 0 1 1 6.666 0A3.333 3.333 0 0 1 .667 4ZM.667 14a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0ZM10.667 4a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0ZM10.667 14a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0Z"
      fill="currentColor"
    />
  </Icon>
));

MenuIcon.displayName = "MenuIcon";
