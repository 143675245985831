import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Icon } from "./Icon";

export const InfoOvalIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      ...props.style,
    }}
  >
    <path
      d="M10 20C4.481 20 0 15.519 0 10S4.481 0 10 0s10 4.481 10 10-4.481 10-10 10Zm.833-5.227V9.148a.833.833 0 0 0-1.666 0v5.625a.833.833 0 0 0 1.666 0ZM10 6.984a.938.938 0 1 1 .001-1.875A.938.938 0 0 1 10 6.984Z"
      style={{
        fill: "currentColor",
      }}
    />
  </Icon>
));

InfoOvalIcon.displayName = "InfoOvalIcon";
