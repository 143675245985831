import styled, { css } from "styled-components";

const labelStyles = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: block;
  padding-bottom: 0.5rem;
`;

export const Label = styled.label(() => labelStyles);
export const LabelText = styled.span(() => labelStyles);
