import {
  radiusNormal,
  semanticGrayBgStrong,
  semanticTextInverse,
  shadowModalsOuterShadow,
  spacingMedium,
  spacingMediumLarge,
  semanticAlertRedText,
  semanticWarningYellowText,
  semanticSuccessGreenText,
  semanticInfoBlueText,
  fontSize16,
} from "@tokens";
import styled, { css, keyframes } from "styled-components";
import { Options, ToastContext } from "./ToastProvider";
import { useEffect, useState } from "react";
import { IconButton } from "@components/icon-button";
import {
  CloseIcon,
  ErrorOvalIcon,
  InfoOvalIcon,
  SuccessOvalIcon,
} from "@materials";
import variant from "sc-variant";

export type ToastProps = {
  id: string;
  content: React.ReactNode;
} & Options &
  Pick<ToastContext, "removeToast">;

const iconDict = {
  success: <SuccessOvalIcon />,
  error: <ErrorOvalIcon />,
  warning: <InfoOvalIcon />,
  information: <InfoOvalIcon />,
};

const FadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const FadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const ToastBlock = styled.li<{ isDismissed: boolean }>`
  list-style-type: none;
  width: 340px;
  max-width: 340px;
  font-size: ${fontSize16};
  padding: ${spacingMediumLarge};
  display: flex;
  gap: ${spacingMedium};
  justify-content: center;
  align-items: center;
  background-color: ${semanticGrayBgStrong};
  box-shadow: ${shadowModalsOuterShadow};
  border-radius: ${radiusNormal};
  color: ${semanticTextInverse};
  opacity: 0;
  animation-name: ${FadeIn};
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  ${({ isDismissed }) =>
    isDismissed &&
    css`
      animation-name: ${FadeOut};
      animation-duration: 200ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    `}
`;

const Grow = styled.span`
  flex-grow: 1;
`;

const IconBlock = styled.div<Pick<Options, "variant">>`
  color: ${variant("variant", {
    information: semanticInfoBlueText,
    success: semanticSuccessGreenText,
    warning: semanticWarningYellowText,
    error: semanticAlertRedText,
  })};
`;

const Toast: React.FC<ToastProps> = ({
  id,
  content,
  autoDismiss = true,
  dismissable = false,
  variant,
  removeToast,
}) => {
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    if (!autoDismiss || autoDismiss === Infinity || variant === "error") return;
    const timer = setTimeout(
      () => {
        handleDismiss();
      },
      autoDismiss === true ? 3500 : autoDismiss,
    );
    return () => clearTimeout(timer);
  }, [removeToast]);

  const handleDismiss = () => {
    setIsDismissed(true);
    setTimeout(() => {
      removeToast(id);
    }, 200);
  };

  return (
    <ToastBlock isDismissed={isDismissed}>
      {variant && <IconBlock variant={variant}>{iconDict[variant]}</IconBlock>}
      {content}
      <Grow />
      {(dismissable ||
        !autoDismiss ||
        autoDismiss === Infinity ||
        variant === "error") && (
        <IconButton onClick={handleDismiss}>
          <CloseIcon />
        </IconButton>
      )}
    </ToastBlock>
  );
};

export default Toast;
