import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const SearchIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      d="M10.17 17.77C6.06998 17.77 2.72998 14.43 2.72998 10.33C2.72998 6.23 6.05998 2.88 10.17 2.88C14.28 2.88 17.61 6.22 17.61 10.32C17.61 14.42 14.27 17.76 10.17 17.76V17.77ZM10.17 5.07C7.26998 5.07 4.90998 7.43 4.90998 10.33C4.90998 13.23 7.26998 15.59 10.17 15.59C13.07 15.59 15.43 13.23 15.43 10.33C15.43 7.43 13.07 5.07 10.17 5.07Z"
      fill="currentColor"
    />
    <path
      d="M21.1645 19.4776L15.6562 13.9693C15.5078 13.8209 15.2671 13.8209 15.1187 13.9693L14.1217 14.9663C13.9733 15.1147 13.9733 15.3553 14.1217 15.5037L19.6301 21.012C19.7785 21.1604 20.0191 21.1604 20.1675 21.012L21.1645 20.015C21.3129 19.8666 21.3129 19.626 21.1645 19.4776Z"
      fill="currentColor"
    />
  </Icon>
));

SearchIcon.displayName = "SearchIcon";
