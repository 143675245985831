import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const InstagramIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    role="img"
    aria-label="Instagram"
    {...props}
  >
    <path
      d="M16.314 2.884c4.275 0 4.777 0 6.466.096a9.04 9.04 0 0 1 2.986.549 4.992 4.992 0 0 1 1.84 1.194c.531.515.94 1.144 1.194 1.84.356.955.544 1.965.555 2.985.078 1.684.09 2.191.09 6.467 0 4.275 0 4.777-.09 6.467a8.803 8.803 0 0 1-.555 2.985 4.904 4.904 0 0 1-1.195 1.84 4.991 4.991 0 0 1-1.839 1.194 9.04 9.04 0 0 1-2.985.55c-1.69.107-2.192.107-6.467.107-4.276 0-4.777 0-6.467-.096a8.958 8.958 0 0 1-2.986-.55 5.339 5.339 0 0 1-3.04-3.039 8.844 8.844 0 0 1-.549-2.985c-.077-1.684-.095-2.192-.095-6.467 0-4.276 0-4.777.095-6.467.01-1.02.196-2.03.55-2.986a5.338 5.338 0 0 1 3.04-3.039 8.957 8.957 0 0 1 2.985-.55c1.684-.077 2.191-.095 6.467-.095Zm0-2.884c-4.347 0-4.897 0-6.569.102a11.77 11.77 0 0 0-3.887.74 8.169 8.169 0 0 0-4.682 4.681A11.787 11.787 0 0 0 .4 9.453C.323 11.16.305 11.703.305 16.02c0 4.317 0 4.89.095 6.568.028 1.329.28 2.643.747 3.888a8.17 8.17 0 0 0 4.681 4.681c1.245.464 2.56.715 3.887.74 1.702.078 2.252.102 6.569.102s4.89 0 6.598-.102a11.835 11.835 0 0 0 3.887-.74 8.211 8.211 0 0 0 4.682-4.681c.464-1.245.714-2.56.74-3.888.078-1.707.096-2.25.096-6.568 0-4.317 0-4.89-.096-6.568a11.804 11.804 0 0 0-.74-3.888A8.21 8.21 0 0 0 26.769.884a11.835 11.835 0 0 0-3.887-.74C21.174.065 20.631.041 16.284.041l.03-.042Z"
      fill="currentColor"
    />
    <path
      d="M16.314 7.797a8.222 8.222 0 1 0-.012 16.444 8.222 8.222 0 0 0 .012-16.444Zm0 13.56a5.339 5.339 0 1 1-.012-10.677 5.339 5.339 0 0 1 .012 10.678ZM24.852 9.398a1.923 1.923 0 1 0 0-3.845 1.923 1.923 0 0 0 0 3.845Z"
      fill="currentColor"
    />
  </Icon>
));

InstagramIcon.displayName = "InstagramIcon";
