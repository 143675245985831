import type { FlatTheme } from "./Theme";
import styled, { createGlobalStyle, css } from "styled-components";
import { themeModeClassName, themeToCssVariables } from "./utils";
import { ThemeMode } from "./ThemeMode";

const darkMode = css<{ theme: Pick<FlatTheme, "dark"> }>`
  ${(props) => themeToCssVariables(props.theme.dark)}
`;
const lightMode = css<{ theme: Pick<FlatTheme, "light"> }>`
  ${(props) => themeToCssVariables(props.theme.light)}
`;
const systemMode = css<{ theme: Partial<FlatTheme> }>`
  @media (prefers-color-scheme: dark) {
    ${darkMode}
  }

  @media (prefers-color-scheme: light) {
    ${lightMode}
  }
`;
const systemInvertedMode = css<{ theme: Partial<FlatTheme> }>`
  @media (prefers-color-scheme: dark) {
    ${lightMode}
  }

  @media (prefers-color-scheme: light) {
    ${darkMode}
  }
`;

const modeMap: Record<ThemeMode, typeof systemMode> = {
  dark: darkMode,
  light: lightMode,
  system: systemMode,
  "system-inverted": systemInvertedMode,
};

export const GlobalThemeStyles = createGlobalStyle<{
  theme: Partial<FlatTheme>;
  mode: ThemeMode;
}>`
  :root, body.${(props) => themeModeClassName({ mode: props.mode })} {
    ${(props) => modeMap[props.mode]}
  }
`;

export const ScopedThemeStyles = styled.div.withConfig({
  shouldForwardProp: (prop) => !["theme", "mode"].includes(prop),
})<{
  theme: Partial<FlatTheme>;
  mode: ThemeMode;
}>`
  & {
    ${(props) => modeMap[props.mode]}
  }
`;
