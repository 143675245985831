import type { CSSProperties } from "react";
import styled, { css } from "styled-components";
import variant from "sc-variant";
import { focusVisible } from "@styles";
import { LoadingIcon } from "@materials";
import {
  componentDestructiveBtnBg,
  componentDestructiveBtnBgHover,
  componentPrimaryBtnBg,
  componentPrimaryBtnBgHover,
  fontWeightSofiaProSemibold,
  semanticBdrStrongest,
  semanticGrayBgDisabled,
  semanticGrayBgStrong,
  semanticGrayBgStrongHover,
  semanticGrayBgWeakest,
  semanticGrayBgWeakestHover,
  semanticPrimaryBgHover,
  semanticPrimaryText,
  semanticTextDisabled,
  semanticTextInverse,
  semanticTextNormal,
  semanticWhiteText,
} from "@tokens";

export interface ButtonProps {
  /**
   * Choose a size for the button
   * @default normal
   */
  size?: "normal" | "small" | "large" | "fullWidth";
  /**
   * What kind of action will this button perform?
   * @default basic
   */
  variant?:
    | "primary"
    | "custom"
    | "important"
    | "subtle"
    | "basic"
    | "destructive"
    /**
     * @deprecated Use Anchor instead
     */
    | "link";

  /**
   * Disables the button, making it unclickable
   * Can be set to "functional" to disable the button without changing its appearance
   */
  disabled?: boolean | "functional";
}

const buttonDisabledStyles = css<ButtonProps>`
  cursor: not-allowed;
  color: ${variant("variant", {
    DEFAULT: semanticTextDisabled,
    custom: `var(--button-disabled-text, ${semanticTextDisabled})`,
  })};
  border-color: ${({ variant }) =>
    variant === "custom"
      ? `var(--button-disabled-border-color, ${semanticGrayBgStrong})`
      : variant === "basic" || variant === undefined
        ? semanticGrayBgStrong
        : undefined};
  background: ${variant("variant", {
    DEFAULT: semanticGrayBgDisabled,
    custom: `var(--button-disabled-bg, ${semanticGrayBgDisabled})`,
    link: null,
    subtle: null,
  })};
`;

const buttonPressedStyle = css<ButtonProps>`
  transition: background-color 0.3s ease;
  background-color: ${({ variant }) =>
    variant === "subtle" ? semanticGrayBgWeakestHover : undefined};
  transform: ${variant(
    "size",
    {
      DEFAULT: "scale(0.985)",
      small: "scale(0.985)",
      normal: "scale(0.985)",
      large: "scale(0.99)",
      fullWidth: "scale(0.99)",
    },
    true,
  )};
`;

export const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !["variant", "size"].includes(prop),
})<ButtonProps>`
  /* BASE */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  font-weight: ${fontWeightSofiaProSemibold};
  border: ${({ variant }) =>
    variant === "custom"
      ? "var(--button-border, none)"
      : variant === "basic" || variant === undefined
        ? `1px solid ${semanticBdrStrongest}`
        : "none"};
  transition:
    box-shadow 0.3s ease,
    border-color 0.3s ease,
    color 0.3s ease,
    background-color 0.3s ease;
  border-radius: 0.5rem;

  /* SIZE SETTINGS */
  font-size: ${variant("size", { DEFAULT: "1rem", large: "1.25rem" })};
  line-height: ${variant("size", { DEFAULT: "1.5rem", large: "1.25rem" })};
  width: ${variant("size", { fullWidth: "100%" })};
  padding: ${({ variant: kind }) =>
    kind === "link"
      ? "0"
      : variant("size", {
          DEFAULT: "1rem 1.5rem",
          small: "0.5rem 1rem",
          large: "1.25rem 3rem",
        })};

  /* VARIANT SETTINGS */
  color: ${variant("variant", {
    DEFAULT: semanticTextNormal,
    custom: `var(--button-text, ${semanticTextNormal})`,
    link: semanticPrimaryText,
    primary: semanticWhiteText,
    important: semanticTextInverse,
    destructive: semanticWhiteText,
  })};
  background: ${variant("variant", {
    DEFAULT: semanticGrayBgWeakest,
    custom: `var(--button-bg, ${semanticGrayBgWeakest})`,
    primary: componentPrimaryBtnBg,
    important: semanticGrayBgStrong,
    destructive: componentDestructiveBtnBg,
    subtle: "transparent",
    link: "transparent",
  })};

  :hover:not([disabled], :active) {
    cursor: pointer;
    transition:
      background-color 50ms linear,
      transform 50ms linear;
    border-color: ${({ variant }) =>
      variant === "custom"
        ? "var(--button-interact-border-color, transparent)"
        : undefined};
    color: ${variant("variant", {
      custom: `var(--button-interact-text, ${semanticTextNormal})`,
      link: semanticPrimaryBgHover,
    })};
    background: ${variant("variant", {
      DEFAULT: semanticGrayBgWeakestHover,
      custom: `var(--button-interact-bg, ${semanticGrayBgWeakestHover})`,
      link: null,
      primary: componentPrimaryBtnBgHover,
      important: semanticGrayBgStrongHover,
      destructive: componentDestructiveBtnBgHover,
    })};
  }

  :active:not([disabled]) {
    cursor: pointer;
    ${buttonPressedStyle}
  }

  ${focusVisible}

  [disabled],
  :disabled {
    ${({ disabled }: ButtonProps) => {
      return disabled === "functional"
        ? buttonPressedStyle
        : buttonDisabledStyles;
    }}
  }
`;

export interface CustomButtonColors {
  "--button-text"?: string;
  "--button-bg"?: string;
  "--button-border"?: string;
  "--button-interact-text"?: string;
  "--button-interact-bg"?: string;
  "--button-interact-border-color"?: string;
  "--button-disabled-text"?: string;
  "--button-disabled-bg"?: string;
  "--button-disabled-border-color"?: string;
  "--outline-color"?: string;
}

export const ButtonLoadingIcon = styled(LoadingIcon).withConfig({
  shouldForwardProp: (prop) => !["solo"].includes(prop),
  displayName: "ButtonLoadingIcon",
})<{ solo?: boolean }>`
  height: 1.5rem;
  margin-right: ${({ solo }) => (solo ? "2rem" : "1rem")};
  margin-left: ${({ solo }) => (solo ? "2rem" : "0")};
`;

export function customButtonStyles(colors: CustomButtonColors) {
  return colors as CSSProperties;
}
