import type { CheckboxProps as RadixCheckboxProps } from "@radix-ui/react-checkbox";
import { CSSProperties, forwardRef, ReactNode } from "react";
import { useId } from "@floating-ui/react";
import styled from "styled-components";
import { CheckBoxCheckedIcon, CheckBoxIndeterminateIcon } from "@materials";
import { spacingMedium, spacingMediumLarge } from "@tokens";
import {
  CheckboxAlign,
  CheckboxIndicator,
  CheckboxLabel,
  CheckboxRoot,
  CheckboxVariant,
} from "./Blocks.Checkbox";

export interface CheckboxBaseProps extends RadixCheckboxProps {
  inline?: boolean;
  hasError?: boolean;
  labelSize?: "normal" | "small";
  variant?: CheckboxVariant;
}

export const CheckboxGroup = styled.div<{ stacked?: boolean }>`
  display: flex;
  flex-direction: ${({ stacked }) => (stacked ? "column" : "row")};
  gap: ${({ stacked }) => (stacked ? spacingMedium : spacingMediumLarge)};
`;

const CheckboxStandalone = forwardRef<HTMLButtonElement, CheckboxBaseProps>(
  ({ checked, variant = "secondary", ...props }, ref) => {
    return (
      <CheckboxRoot {...props} ref={ref} checked={checked} variant={variant}>
        <CheckboxIndicator>
          {checked === "indeterminate" ? (
            <CheckBoxIndeterminateIcon />
          ) : (
            <CheckBoxCheckedIcon />
          )}
        </CheckboxIndicator>
      </CheckboxRoot>
    );
  },
);

/** @deprecated will be removed in a future major version. Use Checkbox without label instead */
export const CheckboxBase = CheckboxStandalone;

export interface CheckboxProps extends CheckboxBaseProps {
  label?: ReactNode;
  labelClassName?: string;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      id: userId,
      label: userLabel,
      children,
      inline,
      labelSize,
      labelClassName,
      ...props
    },
    ref,
  ) => {
    const label = userLabel ?? children;
    const genId = useId();
    const id = userId || (label ? genId : undefined);

    const standaloneUI = <CheckboxStandalone {...props} id={id} ref={ref} />;

    if (label == null) {
      return standaloneUI;
    }

    return (
      <CheckboxAlign inline={inline}>
        {standaloneUI}
        <CheckboxLabel
          htmlFor={id}
          disabled={props.disabled}
          size={labelSize}
          className={labelClassName}
        >
          {label}
        </CheckboxLabel>
      </CheckboxAlign>
    );
  },
);

Checkbox.displayName = "Checkbox";
export interface CustomCheckboxColors {
  "--checkbox-checked"?: string;
  "--checkbox-hover"?: string;
  "--checkbox-error"?: string;
  "--outline-color"?: string;
}

export function customCheckboxStyles(colors: CustomCheckboxColors) {
  return colors as CSSProperties;
}
