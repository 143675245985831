import styled, { css } from "styled-components";
import variant from "sc-variant";
import { Root, Indicator } from "@radix-ui/react-checkbox";
import { focusVisible, transition } from "@styles";
import {
  borderWidthMedium,
  fontSize14,
  fontSize16,
  radiusSmall,
  semanticAlertRedBg,
  semanticBdrNormal,
  semanticBdrStronger,
  semanticGrayBgDisabled,
  semanticGrayBgStrong,
  semanticGrayBgStrongHover,
  semanticPrimaryBg,
  semanticPrimaryBgHover,
  semanticSecondaryBg,
  semanticSecondaryBgHover,
  semanticTextDisabled,
  spacingMedium,
  spacingSmall,
} from "@tokens";

export type CheckboxVariant = "primary" | "secondary" | "neutral";

export const errorStyles = css`
  &:not([disabled]) {
    color: var(--checkbox-error, ${semanticAlertRedBg});
  }
`;

export const checkedStyles = css<{ variant?: CheckboxVariant }>`
  transition: ${transition(["color"])};

  &[data-state="checked"],
  &[data-state="indeterminate"] {
    color: var(
      --checkbox-checked,
      ${variant("variant", {
        DEFAULT: semanticSecondaryBg,
        primary: semanticPrimaryBg,
        neutral: semanticGrayBgStrong,
      })}
    );
  }

  &:hover:not([disabled]) {
    color: var(
      --checkbox-checked,
      ${variant("variant", {
        DEFAULT: semanticSecondaryBg,
        primary: semanticPrimaryBg,
        neutral: semanticGrayBgStrong,
      })}
    );

    &[data-state="checked"],
    &[data-state="indeterminate"] {
      color: var(
        --checkbox-hover,
        ${variant("variant", {
          DEFAULT: semanticSecondaryBgHover,
          primary: semanticPrimaryBgHover,
          neutral: semanticGrayBgStrongHover,
        })}
      );
    }
  }
`;

export const CheckboxRoot = styled(Root).withConfig({
  shouldForwardProp: (prop) => !["hasError"].includes(prop),
})<{ hasError?: boolean; variant?: CheckboxVariant; inline?: boolean }>`
  border: none;
  color: ${semanticBdrStronger};
  width: 20px;
  height: 20px;
  background: transparent;
  padding: 0;
  border-radius: ${radiusSmall};
  overflow: hidden;
  display: ${({ inline = true }) => (inline ? "inline-flex" : "flex")};
  align-items: center;
  justify-content: center;

  &[data-state="unchecked"] {
    border: ${borderWidthMedium} solid currentColor;
  }

  ${({ hasError }) => (hasError ? errorStyles : checkedStyles)}
  ${focusVisible}
  ${transition(["color", "border-color"])}

  &[disabled] {
    background: ${semanticGrayBgDisabled};
    border-color: ${semanticBdrNormal};
    color: ${semanticTextDisabled};
  }
`;

export const CheckboxIndicator = styled(Indicator)`
  width: 20px;
  height: 20px;
  display: block;
`;

export const CheckboxLabel = styled.label<{
  disabled?: boolean;
  size?: "normal" | "small";
}>`
  color: ${({ disabled }) =>
    disabled ? semanticTextDisabled : "currentColor"};
  font-size: ${({ size }) => (size === "small" ? fontSize14 : fontSize16)};
  ${transition(["color"])}
  padding-right: ${spacingSmall};
`;

export const CheckboxAlign = styled.div<{ inline?: boolean }>`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  align-items: center;
  justify-content: left;
  gap: ${spacingMedium};
`;
