import { ComponentPropsWithoutRef, forwardRef } from "react";
import styled from "styled-components";

export const ChevronIconSvg = styled.svg`
  width: 1rem;
`;

type ChevronOrientation = "down" | "up" | "left" | "right";

const orientationMap: Record<ChevronOrientation, number> = {
  down: 0,
  up: 180,
  left: 90,
  right: 270,
};

export const ChevronIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg"> & {
    orientation?: ChevronOrientation | number;
  }
>(({ orientation = "down", style, ...props }, ref) => {
  const rotation =
    typeof orientation === "number"
      ? orientation
      : orientationMap[orientation] || 0;

  // We want to shift the icon slightly to the side when it's pointing left or right
  const offset =
    Math.round(Math.abs(Math.sin((rotation * Math.PI) / 180)) * 15) / 10;

  return (
    <ChevronIconSvg
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      style={{
        transform: `rotate(${rotation}deg) translate(0px, ${offset}px)`,
        transformOrigin: "center",
        ...style,
      }}
      {...props}
    >
      <path
        d="M11.58 1a.996.996 0 0 0-1.41 0L6.29 4.88 2.41 1A.996.996 0 1 0 1 2.41L5.59 7c.39.39 1.02.39 1.41 0l4.59-4.59c.38-.38.38-1.02-.01-1.41Z"
        fill="currentColor"
      />
    </ChevronIconSvg>
  );
});

ChevronIcon.displayName = "ChevronIcon";

export const ChevronDownIcon = ChevronIcon;
