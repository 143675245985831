import styled, { css } from "styled-components";
import variant from "sc-variant";
import { focusVisible, transition } from "@styles";
import {
  semanticIconHover,
  semanticPrimaryBg,
  semanticSecondaryBg,
  semanticTextDisabled,
} from "@tokens";
import { ReactNode } from "react";
import { XSmall } from "@components/typography";

export type ButtonVariant = "primary" | "secondary";

export interface IconButtonProps {
  variant?: ButtonVariant;
  unread?: number | boolean;
  active?: boolean;
  children?: ReactNode;
}

type variant = Pick<IconButtonProps, "variant">;

const unreadDot = css<variant>`
  ::after {
    content: "";
    position: absolute;
    width: 0.42rem;
    height: 0.42rem;
    background-color: ${variant("variant", {
      DEFAULT: semanticPrimaryBg,
      secondary: semanticSecondaryBg,
    })};
    border-radius: 100%;
    top: 0.38rem;
    right: 0.4375rem;
  }

  [disabled]::after,
  :disabled::after {
    background: ${semanticTextDisabled};
  }
`;

export const iconButtonStyles = css`
  position: relative;
  background-color: transparent;
  height: var(--icon-button-size, 2rem);
  width: var(--icon-button-size, 2rem);
  padding: 0;
  border: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  ${transition(["background-color"])}

  &:not([disabled]) {
    cursor: pointer;
  }
`;

const NumericUnreadDot = styled(XSmall)<variant>`
  display: flex;
  background-color: ${variant("variant", {
    DEFAULT: semanticPrimaryBg,
    secondary: semanticSecondaryBg,
  })};
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 100%;
  top: -0.25rem;
  right: 0rem;
  padding-bottom: 2px;
  width: 1.25rem;
  height: 1.25rem;
`;

const NumberBadge = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.08rem;
`;

export const IconButton = styled.button.attrs(
  ({ unread, children, variant, ...props }: IconButtonProps) => {
    if (typeof unread === "number") {
      return {
        ...props,
        children: (
          <>
            {children}
            {unread > 0 && (
              <NumericUnreadDot variant={variant}>
                {unread <= 10 ? unread : <NumberBadge>10+</NumberBadge>}
              </NumericUnreadDot>
            )}
          </>
        ),
        unread: false,
      };
    }

    return {
      ...props,
      children,
    };
  },
)<IconButtonProps>`
  ${iconButtonStyles}

  &:hover:not([disabled]) {
    background-color: ${semanticIconHover};
  }

  &:active:not([disabled]) {
    background-color: transparent;
  }

  background-color: ${({ active }) => active && semanticIconHover};

  ${focusVisible}
  ${({ unread }) => unread && unreadDot}

[disabled], :disabled {
    color: ${semanticTextDisabled};
  }
`;
