import type { InputProps } from "@components/input";
import styled from "styled-components";
import { inputStyles } from "@components/input/index.direct";

export type TextAreaProps = InputProps & {
  resize?: "vertical" | "horizontal" | "both" | "none";
};

export const TextArea = styled.textarea<TextAreaProps>`
  ${inputStyles}
  resize: ${({ resize }) => resize};
`;
