import { ComponentPropsWithoutRef, ReactNode } from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHeadRow,
  TableRow,
  TableHeaderCell,
  SortIcon,
  TableHead,
  SortDirection,
  HoverIcon,
  FinalColWrap,
} from "./Blocks.Table";
import { minLarge, minMedium, minXlarge } from "@tokens";

export interface TableData {
  id: string | number;
}

interface BaseTableColumn {
  label?: ReactNode;
  showMediaQuery?: string;
}

export interface TableColumnCommon<DataType extends TableData>
  extends BaseTableColumn {
  dataKey: keyof DataType;
  key?: never;
  sortable?: boolean;
  render?: (row: DataType) => ReactNode;
}

export interface TableColumnCustom<
  DataType extends TableData,
  CustomColumnKeys extends string = string,
> extends BaseTableColumn {
  key: keyof DataType | CustomColumnKeys;
  dataKey?: never;
  sortable?: never;
  sorts?: keyof DataType;
  render: (row: DataType) => ReactNode;
}

export type TableColumn<
  DataType extends TableData,
  CustomColumnKeys extends string = string,
> = TableColumnCommon<DataType> | TableColumnCustom<DataType, CustomColumnKeys>;

export interface SortState<
  DataType extends TableData = TableData & Record<string, unknown>,
> {
  direction: SortDirection;
  /** @deprecated use `key` instead */
  column?: keyof DataType;
  key?: keyof DataType;
}

export type Interactable =
  | "interactive"
  | "interactiveWithIcon"
  | "nonInteractive";

export type CompactBreakPoint = "small" | "medium" | "large" | "none";

export interface TableProps<
  DataType extends TableData,
  CustomColumnKeys extends string = string,
> {
  /** The data structure populating the table */
  data: DataType[];
  /** Each column of the table with its own respective settings and custom functions */
  columns: TableColumn<DataType, CustomColumnKeys>[];
  /**
   * Toggle at what window size the table display will switch to compact display
   * @deprecated prefer using the `showMediaQuery` setting on the column instead
   */
  compactViewBreakPoint?: CompactBreakPoint;
  /**
   * Toggle which column is the primary column that is shown in the table's compact display type setting
   * @deprecated prefer using the `showMediaQuery` setting on the column instead
   */
  primaryColumnKey?: keyof DataType | CustomColumnKeys;
  /** Change handler that receives new sort settings to sort the data by */
  onSortChange?: (settings: SortState<DataType>) => void;
  /** The current sort settings the table is set to  */
  sortState?: SortState<DataType>;
  /** Change handler that receives the data item matching the row that was clicked on */
  onRowClick?: (item: DataType) => void;
  /** Toggle whether each row provides feedback on hover and focus - likely want this set to "interactive" or "interactiveWithIcon" when onRowClick is enabled */
  interactive?: Interactable;
  /** Toggle if the table's header has a background */
  headerBackground?: boolean;
  /** Customize props per single row of the table (not called for the header row) */
  getRowProps?: (
    props: ComponentPropsWithoutRef<typeof TableRow>,
    row: DataType,
  ) => ComponentPropsWithoutRef<typeof TableRow>;
  /**
   * Set true, to render the table div elements rather then native `<table />` semantics.
   * This for example useful when rendering the rows as links.
   */
  emulated?: boolean;
}

export function Table<
  DataType extends TableData,
  CustomColumnKeys extends string = string,
>({
  data,
  columns,
  compactViewBreakPoint,
  primaryColumnKey = isCustomTableColumn(columns[0])
    ? columns[0].key
    : columns[0].dataKey,
  onSortChange,
  sortState,
  onRowClick,
  interactive,
  headerBackground,
  getRowProps = defaultGetRowProps,
  emulated,
  ...tableProps
}: TableProps<DataType, CustomColumnKeys> & ComponentPropsWithoutRef<"table">) {
  return (
    <TableContainer {...tableProps} as={emulated ? "div" : "table"}>
      <TableHead as={emulated ? "div" : "thead"}>
        <TableHeadRow hdrBgnd={headerBackground} as={emulated ? "div" : "tr"}>
          {columns.map((column) => (
            <HeaderCell
              as={emulated ? "div" : "td"}
              key={columnToKey(column)}
              column={column}
              primaryColumnKey={primaryColumnKey}
              sortState={sortState}
              compactViewBreakPoint={compactViewBreakPoint}
              onSortChange={onSortChange}
            />
          ))}
        </TableHeadRow>
      </TableHead>
      <TableBody as={emulated ? "div" : "tbody"}>
        {data.map((row) => (
          <TableRow
            {...getRowProps(
              {
                as: emulated ? "div" : "tr",
                tabIndex: 0,
                onClick: onRowClick ? () => onRowClick(row) : undefined,
                key: row.id,
                clickable:
                  interactive === "interactiveWithIcon" ||
                  interactive === "interactive",
                onKeyDown: (e: React.KeyboardEvent<HTMLTableRowElement>) =>
                  handleKeyDown(
                    e,
                    onRowClick ? () => onRowClick(row) : undefined,
                  ),
                showHoverIcon: interactive === "interactiveWithIcon",
              },
              row,
            )}
          >
            {columns.map((column, index) => (
              <BodyCell
                as={emulated ? "div" : "td"}
                key={columnToKey(column)}
                interactive={interactive}
                compactViewBreakPoint={compactViewBreakPoint}
                primaryColumnKey={primaryColumnKey}
                column={column}
                row={row}
                finalCol={
                  index === columns.length - 1 && interactive ? true : undefined
                }
              />
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
}

//Handle 'enter' key down functionality on table row and header cells
function handleKeyDown(
  event:
    | React.KeyboardEvent<HTMLTableRowElement>
    | React.KeyboardEvent<HTMLTableCellElement>,
  callback?: () => void,
) {
  if (callback && ["Enter", " "].includes(event.key)) {
    event.preventDefault();
    callback();
  }
}

const BreakPointValues = {
  small: minMedium,
  medium: minLarge,
  large: minXlarge,
};
interface GetShowMediaQueryOpts {
  isPrimaryColumn: boolean;
  compactViewBreakPoint?: CompactBreakPoint;
  showMediaQuery?: string;
}
function getShowMediaQuery({
  compactViewBreakPoint,
  isPrimaryColumn,
  showMediaQuery,
}: GetShowMediaQueryOpts) {
  /* when we have an explicit media query for the column, use that one */
  if (showMediaQuery != null) {
    return showMediaQuery;
  }

  if (
    /* primary column is always shown */
    isPrimaryColumn ||
    /* everything is always shown when we don't have a breakpoint */
    !compactViewBreakPoint ||
    /* or it's configured to none */
    compactViewBreakPoint === "none"
  ) {
    return undefined;
  }

  return BreakPointValues[compactViewBreakPoint];
}

function BodyCell<
  DataType extends TableData,
  CustomColumnKeys extends string = string,
>({
  column,
  row,
  finalCol,
  interactive,
  compactViewBreakPoint,
  primaryColumnKey,
  as,
}: {
  column: TableColumn<DataType, CustomColumnKeys>;
  row: DataType;
  finalCol?: boolean;
  as?: "td" | "div";
} & Pick<
  TableProps<DataType>,
  "interactive" | "compactViewBreakPoint" | "primaryColumnKey"
>) {
  const key = isCustomTableColumn(column) ? column.key : column.dataKey;

  let children: ReactNode = null;
  if (isCustomTableColumn(column) || column.render) {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    children = column.render!(row);
  } else {
    children = String(row[column.dataKey]);
  }

  const hoverIcon =
    finalCol && interactive === "interactiveWithIcon" ? (
      <HoverIcon orientation={"right"} />
    ) : null;

  return (
    <TableCell
      as={as}
      key={columnToKey(column)}
      showMediaQuery={getShowMediaQuery({
        compactViewBreakPoint,
        showMediaQuery: column.showMediaQuery,
        isPrimaryColumn: key === primaryColumnKey,
      })}
    >
      {hoverIcon ? (
        <FinalColWrap>
          <div>{children}</div>
          {hoverIcon}
        </FinalColWrap>
      ) : (
        children
      )}
    </TableCell>
  );
}

function HeaderCell<
  DataType extends TableData,
  CustomColumnKeys extends string = string,
>({
  column,
  onSortChange,
  sortState,
  compactViewBreakPoint,
  primaryColumnKey,
  as,
}: {
  column?: TableColumn<DataType, CustomColumnKeys>;
  as?: "td" | "div";
} & Pick<
  TableProps<DataType, CustomColumnKeys>,
  "onSortChange" | "sortState" | "compactViewBreakPoint" | "primaryColumnKey"
>) {
  if (!column) {
    /* TODO: On a future breaking change remove this and make column required */
    return null;
  }

  const key = isCustomTableColumn(column) ? column.key : column.dataKey;
  const sorts = isCustomTableColumn(column)
    ? column.sorts
    : column.sortable && column.dataKey;
  const sortable = Boolean(sorts);

  const sortChange =
    onSortChange && sorts
      ? () =>
          onSortChange({
            direction: newSortDirection,
            column: sorts,
            key: sorts,
          })
      : undefined;

  const currentSortKey = sortState?.key || sortState?.column;
  let newSortDirection: SortDirection = SortDirection.ASCENDING;
  if (sortState && currentSortKey === sorts) {
    switch (sortState.direction) {
      case SortDirection.ASCENDING:
        newSortDirection = SortDirection.DESCENDING;
        break;
      case SortDirection.DESCENDING:
        newSortDirection = SortDirection.NONE;
        break;
      case SortDirection.NONE:
        newSortDirection = SortDirection.ASCENDING;
        break;
    }
  }

  return (
    <TableHeaderCell
      as={as}
      active={
        sortState &&
        currentSortKey === sorts &&
        (sortState.direction === SortDirection.ASCENDING ||
          sortState.direction === SortDirection.DESCENDING)
      }
      sortable={sortable}
      tabIndex={sortable ? 0 : undefined}
      showMediaQuery={getShowMediaQuery({
        compactViewBreakPoint,
        showMediaQuery: column.showMediaQuery,
        isPrimaryColumn: key === primaryColumnKey,
      })}
      onClick={sortChange}
      onKeyDown={(e: React.KeyboardEvent<HTMLTableCellElement>) =>
        handleKeyDown(e, sortChange)
      }
    >
      {column.label === false || column.label === ""
        ? null
        : column.label || toKey(key)}
      {sortable && onSortChange && (
        <SortIcon
          sortDirection={
            sortState && currentSortKey === sorts
              ? sortState.direction
              : SortDirection.NONE
          }
        />
      )}
    </TableHeaderCell>
  );
}

Table.displayName = "Table";

export default Table;

export function isCustomTableColumn<
  DataType extends TableData,
  CustomColumnKeys extends string = string,
>(
  column: TableColumn<DataType, CustomColumnKeys>,
): column is TableColumnCustom<DataType, CustomColumnKeys> {
  return (
    (column as TableColumnCustom<DataType, CustomColumnKeys>).key !== undefined
  );
}

function columnToKey<DataType extends TableData>(
  column: TableColumn<DataType>,
) {
  return toKey(isCustomTableColumn(column) ? column.key : column.dataKey);
}

function toKey(key: string | number | symbol): string | number {
  return typeof key === "symbol" ? key.toString() : key;
}

function defaultGetRowProps<T>(props: T) {
  return props;
}
