import { css } from "styled-components";
import { focusVisible, transition } from "@styles";
import {
  fontWeightSofiaProBold,
  semanticPrimaryBg,
  semanticPrimaryBgHover,
  semanticTextDisabled,
  semanticTextNormal,
} from "@tokens";

/* <a> */
export const commonAnchorStyles = css`
  --outline-offset: 1px;

  border-radius: 1px;
  cursor: pointer;
  ${focusVisible}
  ${transition(["color"])}
  text-decoration: none;

  &[disabled] {
    cursor: not-allowed;
    color: ${semanticTextDisabled};
  }

  /* reset button and input styles */
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
`;
export const anchorStyles = css`
  color: ${semanticPrimaryBg};

  &:hover:not([disabled]) {
    color: ${semanticPrimaryBgHover};
  }
  ${commonAnchorStyles}
`;
export const subtleAnchorStyles = css`
  font-weight: ${fontWeightSofiaProBold};
  color: ${semanticTextNormal};

  &:hover:not([disabled]) {
    text-decoration: underline;
  }

  ${commonAnchorStyles}
`;
export const textAnchorStyle = css`
  color: ${semanticTextNormal};

  &:hover:not([disabled]) {
    text-decoration: underline;
  }

  ${commonAnchorStyles}
`;
