import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const FacebookIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    role="img"
    aria-label="Facebook"
    {...props}
  >
    <path
      d="M28.237 0H4.25A3.99 3.99 0 0 0 .277 4v24a3.996 3.996 0 0 0 3.992 4h13.073V19.615h-4.15V14.78h4.15v-3.56c0-4.129 2.521-6.382 6.201-6.382a35.38 35.38 0 0 1 3.724.19v4.317H24.69c-1.996 0-2.38.952-2.38 2.35v3.054h4.821l-.61 4.837h-4.163V32h5.92a3.996 3.996 0 0 0 3.998-4V4a4 4 0 0 0-4.04-4Z"
      fill="currentColor"
    />
  </Icon>
));

FacebookIcon.displayName = "FacebookIcon";
