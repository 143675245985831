import { createContext, useCallback, useContext, useState } from "react";
import Toast, { ToastProps } from "./Toast";
import styled from "styled-components";
import { spacingMedium } from "@tokens";

export type Options = {
  dismissable?: boolean;
  autoDismiss?: boolean | number;
  variant?: "success" | "error" | "warning" | "information";
};

export type ToastContext = {
  /**
   * Add a toast to the toast queue
   * @param content The content of the toast
   * @param options Options for the toast
   * @param options.autoDismiss Whether the toast should be automatically dismissed. `true` will dismiss the toast after 3.5 seconds, `false`, `Infinity` or `0` will not dismiss the toast, a number will dismiss the toast after that many milliseconds
   * @param options.dismissable Whether the toast can be dismissed
   * @param options.variant The variant of the toast
   * @example
   * const { addToast } = useToast();
   * addToast("I'm a toast");
   * addToast("I'm a toast that will be removed in 5 seconds", { autoDismiss: 5000 });
   */
  addToast: (content: React.ReactNode, options?: Options) => string;
  removeToast: (id: string) => void;
};

export type ToastProviderProps = {
  children: React.ReactNode;
};

const ToastContainer = styled.ul`
  position: fixed;
  bottom: ${spacingMedium};
  right: ${spacingMedium};
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  gap: ${spacingMedium};
`;

const ToastContext = createContext<ToastContext>({
  addToast: () => "",
  removeToast: () => null,
});

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const removeToast: ToastContext["removeToast"] = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
    },
    [setToasts],
  );

  const addToast: ToastContext["addToast"] = useCallback(
    (
      content,
      options = { autoDismiss: true, dismissable: false, variant: undefined },
    ) => {
      const id = crypto.randomUUID();
      setToasts((toasts) => [
        { id, content, ...options, removeToast },
        ...toasts,
      ]);
      return id;
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer>
        {toasts.map((toast) => (
          <Toast key={toast.id} {...toast} />
        ))}
      </ToastContainer>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
