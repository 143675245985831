import styled from "styled-components";
import { Side, ToggleProps } from "./Toggle";
import { Root, Thumb } from "@radix-ui/react-switch";
import {
  semanticGrayBg,
  semanticGrayBgDisabled,
  semanticGrayBgMedium,
  semanticIconDisabled,
  semanticPrimaryBg,
  semanticPrimaryBgHover,
  semanticSecondaryBg,
  semanticSecondaryBgHover,
  semanticWhiteBg,
} from "@tokens";
import { focusVisible } from "@styles";

export const Label = styled.label<{ side: Side }>`
  display: flex;
  flex-direction: ${(props) => (props.side === "left" ? "row" : "row-reverse")};
  align-items: center;
  gap: 16px;
`;

export const ToggleThumb = styled(Thumb)`
  display: block;
  width: 26px;
  height: 26px;
  top: 3px;
  left: 3px;
  background-color: ${semanticWhiteBg};
  border-radius: 50px;
  transition: transform 100ms;
  transform: translateX(-2px);
  will-change: transform;

  &[data-state="checked"] {
    transform: translateX(21px);
  }
`;

export const ToggleRoot = styled(Root)<ToggleProps>`
  width: 57px;
  height: 32px;
  background-color: ${semanticGrayBgMedium};
  border-radius: 32px;
  position: relative;
  border: none;

  :disabled {
    background-color: ${semanticGrayBgDisabled};
    cursor: not-allowed;
    > ${ToggleThumb} {
      background-color: ${semanticIconDisabled};
    }
  }

  :hover:not(:disabled) {
    cursor: pointer;
    background-color: ${semanticGrayBg};
  }

  &[data-state="checked"] {
    background-color: ${(props) =>
      props.variant === "primary" ? semanticPrimaryBg : semanticSecondaryBg};
    transition: background-color 100ms;

    :disabled {
      background-color: ${(props) =>
        props.variant === "primary"
          ? semanticPrimaryBgHover
          : semanticSecondaryBgHover};
    }

    :hover:not(:disabled) {
      background-color: ${(props) =>
        props.variant === "primary"
          ? semanticPrimaryBgHover
          : semanticSecondaryBgHover};
    }
  }

  ${focusVisible}
`;
