import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Icon } from "./Icon";

export const CloseIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="-3 -3 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.69.321a1.057 1.057 0 0 0-1.498 0L7 5.503 1.808.31A1.057 1.057 0 1 0 .31 1.808L5.503 7 .31 12.192a1.057 1.057 0 1 0 1.497 1.497L7 8.497l5.192 5.192a1.057 1.057 0 1 0 1.497-1.497L8.497 7l5.192-5.192a1.064 1.064 0 0 0 0-1.487Z"
      fill="currentColor"
    />
  </Icon>
));

CloseIcon.displayName = "CloseIcon";
