import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const LinkedInIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    role="img"
    aria-label="LinkedIn"
    {...props}
  >
    <path
      d="M28.277 0h-24a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h24a4.001 4.001 0 0 0 4.006-4V4a4 4 0 0 0-4.006-4ZM9.774 27.267H5.022V12h4.752v15.267ZM7.398 9.893a2.754 2.754 0 1 1 2.54-1.7 2.748 2.748 0 0 1-2.54 1.7ZM27.55 27.25h-4.752V19.84c0-1.77 0-4.049-2.442-4.049-2.443 0-2.846 1.93-2.846 3.927v7.548h-4.77V12h4.55v2.089h.067a4.982 4.982 0 0 1 4.489-2.443c4.806 0 5.697 3.157 5.697 7.273l.007 8.33Z"
      fill="currentColor"
    />
  </Icon>
));

LinkedInIcon.displayName = "LinkedInIcon";
