import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const TwitterIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    role="img"
    aria-label="Twitter"
    {...props}
  >
    <path
      d="M28.279 32H4.285a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4H28.28a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4Zm-15.707-7.872c7.548 0 11.677-6.253 11.677-11.676v-.531a8.36 8.36 0 0 0 2.045-2.126 8.077 8.077 0 0 1-2.357.648 4.104 4.104 0 0 0 1.79-2.266 8.429 8.429 0 0 1-2.602.996 4.104 4.104 0 0 0-6.992 3.737 11.604 11.604 0 0 1-8.459-4.275 4.104 4.104 0 0 0 1.27 5.496 3.982 3.982 0 0 1-1.831-.513v.055a4.097 4.097 0 0 0 3.291 4.019 4.091 4.091 0 0 1-1.832.073 4.122 4.122 0 0 0 3.835 2.852 8.244 8.244 0 0 1-5.099 1.752c-.326 0-.653-.018-.977-.055a11.633 11.633 0 0 0 6.29 1.833"
      fill="CurrentColor"
    />
  </Icon>
));

TwitterIcon.displayName = "TwitterIcon";
