import type { StyledComponentBase } from "styled-components";
import type { DynamicToken } from "@tokens";
import {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  createContext,
  forwardRef,
  useContext,
} from "react";
import { THEME_INVERSIONS, ThemeMode } from "./ThemeMode";
import { useFlattenedTheme } from "./flattenTheme";
import { GlobalThemeStyles, ScopedThemeStyles } from "./Styles";
import { themeModeClassName, useThemeModeOnElement } from "./utils";

export type ThemeOverwrite = {
  dark?: Partial<Record<DynamicToken, string>>;
  light?: Partial<Record<DynamicToken, string>>;
  common?: Partial<Record<DynamicToken, string>>;
};

export type Theme<
  Extends extends Record<string, string> = Record<string, string>,
> = {
  overwrites?: ThemeOverwrite[];
  extend?: {
    dark?: Extends;
    light?: Extends;
    common?: Record<string, string>;
  };
};

export interface FlatTheme {
  dark: Record<string, string>;
  light: Record<string, string>;
}

export interface ThemeContextType {
  theme: FlatTheme;
  mode: ThemeMode;
}
export const ThemeContext = createContext<ThemeContextType | null>(null);

type ScopedThemeProps = Theme & { mode?: ThemeMode | "invert" };
export const ScopedTheme = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div"> & ScopedThemeProps
>(({ extend, overwrites, mode, className, ...props }, ref) => {
  const ctx = useContext(ThemeContext);
  const theme = useFlattenedTheme({ extend, overwrites }, ctx?.theme);
  const nextMode =
    mode === "invert"
      ? THEME_INVERSIONS[ctx?.mode || "system"]
      : mode || ctx?.mode || "system";

  return (
    <ThemeContext.Provider value={{ theme, mode: nextMode }}>
      <ScopedThemeStyles
        {...props}
        ref={ref}
        mode={nextMode}
        theme={theme}
        className={themeModeClassName({ mode: nextMode, className })}
      />
    </ThemeContext.Provider>
  );
}) /* Cast to styled component to support as={Element} */ as StyledComponentBase<
  "div",
  never,
  ScopedThemeProps,
  never
>;

export function GlobalTheme({
  extend,
  overwrites,
  mode,
  children,
}: PropsWithChildren<Theme & { mode?: ThemeMode }>) {
  const ctx = useContext(ThemeContext);

  if (process.env.NODE_ENV !== "production" && ctx !== null) {
    // eslint-disable-next-line no-console
    console.warn(
      `Nested use of <GlobalTheme /> detected. This will likely lead to unexpected behavior.
<GlobalTheme /> should only be used once at the root of the app`,
    );
  }

  const nextMode = mode || ctx?.mode || "system";
  const theme = useFlattenedTheme({ extend, overwrites }, ctx?.theme);
  useThemeModeOnElement(
    nextMode,
    typeof document === "undefined" ? null : document.body,
  );

  return (
    <ThemeContext.Provider value={{ theme, mode: nextMode }}>
      <GlobalThemeStyles theme={theme} mode={nextMode} />
      {children}
    </ThemeContext.Provider>
  );
}

/**
 * DON'T USE THIS TO OVERWRITE OR EXTEND THE THEME!
 *
 * This is an escape hatch for when you need to provide additional mode-aware
 * global CSS variables from a nested child component.
 *
 * These variables will not interact with `<ScopedTheme />` or `<GlobalTheme />`
 * and will not respect mode switches by parent `<ScopedTheme />` components.
 *
 * To augment `<GlobalTheme />` from within a child you need to lift the
 * child theme up to the root and merge it with the global theme.
 */
export function GlobalCSSVariables({
  mode,
  dark,
  light,
}: Partial<FlatTheme> & { mode?: ThemeMode }) {
  const ctx = useContext(ThemeContext);

  return (
    <GlobalThemeStyles
      theme={{ dark, light }}
      mode={mode || ctx?.mode || "system"}
    />
  );
}
