import styled, { css } from "styled-components";
import {
  semanticBackgroundMiddle,
  semanticBdrStrong,
  semanticBdrWeak,
  semanticGrayBgWeak,
  fontFamilySofiaPro,
  fontSize14,
  semanticIconHover,
  semanticIconNormal,
  semanticIconDisabled,
  semanticIconWeak,
  semanticTextNormal,
} from "@tokens";
import { focusVisible } from "@styles";
import { ChevronIcon } from "@materials";

export enum SortDirection {
  ASCENDING,
  DESCENDING,
  NONE,
}

export const TableContainer = styled.table`
  width: 100%;
  display: table;
  border-collapse: collapse;
`;
TableContainer.displayName = "TableContainer";

export const TableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
`;
TableBody.displayName = "TableBody";

export const TableHead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
`;
TableHead.displayName = "TableHead";

export const TableCell = styled.td.withConfig({
  shouldForwardProp: (prop) => !["showMediaQuery"].includes(prop),
})(({ showMediaQuery }: { showMediaQuery?: string }) => {
  return css`
    display: ${showMediaQuery ? "none" : "table-cell"};
    vertical-align: inherit;
    padding: 1.5rem;
    font-family: ${fontFamilySofiaPro};
    word-break: break-word;

    ${showMediaQuery &&
    css`
      @media ${showMediaQuery} {
        display: table-cell;
      }
    `}
  `;
});
TableCell.displayName = "TableCell";

export const TableHeaderCell = styled(TableCell).withConfig({
  shouldForwardProp: (prop) => !["active", "sortable"].includes(prop),
})(({ active, sortable }: { active?: boolean; sortable?: boolean }) => {
  return css`
    vertical-align: middle;
    cursor: ${sortable && "pointer"};
    > ${SortIcon} {
      fill: ${active && semanticIconWeak};
    }

    &:hover,
    :focus {
      > ${SortIcon} {
        background: ${active ? semanticIconHover : "transparent"};
        fill: ${active ? semanticIconNormal : semanticIconDisabled};
      }
    }

    :focus:not(:focus-visible):not(:hover) {
      > ${SortIcon} {
        fill: ${!active && "transparent"};
        background: transparent;
      }
    }

    ${focusVisible}
  `;
});

export const HoverIcon = styled(ChevronIcon)`
  flex-grow: 0;
  opacity: 0;
  cursor: pointer;
`;

export const TableRow = styled.tr.withConfig({
  shouldForwardProp: (prop) => !["clickable", "showHoverIcon"].includes(prop),
})(
  ({
    clickable,
    showHoverIcon,
  }: {
    clickable?: boolean;
    showHoverIcon?: boolean;
  }) => {
    return css`
      border-bottom: 0.5px solid ${semanticBdrWeak};
      width: 100%;
      display: table-row;
      vertical-align: inherit;
      text-decoration: none;
      color: ${semanticTextNormal};
      table-layout: fixed;
      font-size: ${fontSize14};

      :hover,
      :focus {
        background: ${clickable && semanticBackgroundMiddle};
        cursor: ${clickable && "pointer"};

        > ${TableCell}:last-child {
          ${HoverIcon} {
            opacity: ${showHoverIcon && 1};
          }
        }
      }

      :focus:not(:focus-visible):not(:hover) {
        background: transparent;
        > ${TableCell}:last-child {
          ${HoverIcon} {
            opacity: 0;
          }
        }
      }

      ${focusVisible}
    `;
  },
);
TableRow.displayName = "TableRow";

export const TableHeadRow = styled(TableRow).withConfig({
  shouldForwardProp: (prop) => !["hdrBgnd"].includes(prop),
})(({ hdrBgnd }: { hdrBgnd?: boolean }) => {
  return css`
    border-bottom: 1px solid ${semanticBdrStrong};
    background-color: ${hdrBgnd && semanticGrayBgWeak};
    font-weight: bold;
    font-size: ${fontSize14};

    button {
      margin-left: 0.5rem;
    }
  `;
});

export const SortIcon = styled.svg.attrs(() => ({
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  children: (
    <>
      <path d="M11.725 18.88L5.75501 12.91C5.59501 12.75 5.59501 12.5 5.75501 12.34L6.81501 11.28C6.97501 11.12 7.22501 11.12 7.38501 11.28L11.715 15.61C11.875 15.77 12.125 15.77 12.285 15.61L16.615 11.28C16.775 11.12 17.025 11.12 17.185 11.28L18.245 12.34C18.405 12.5 18.405 12.75 18.245 12.91L12.275 18.88C12.115 19.04 11.865 19.04 11.705 18.88L11.725 18.88Z" />
      <path d="M10.8401 5.3482L10.8401 16.6518C10.8401 16.8441 11.0237 17 11.2501 17L12.7401 17C12.9665 17 13.1501 16.8441 13.1501 16.6518L13.1501 5.34819C13.1501 5.15589 12.9665 5 12.7401 5L11.2501 5C11.0237 5 10.8401 5.15589 10.8401 5.3482Z" />
    </>
  ),
}))<{ sortDirection: SortDirection }>`
  vertical-align: middle;
  display: inline-block;
  height: 1.25rem;
  border-radius: 100%;
  margin-left: 0.25rem;
  transform: ${(props) =>
    props.sortDirection === SortDirection.DESCENDING
      ? "rotate(180deg) translateY(0.1rem)"
      : "translateY(-0.1rem)"};
  transform-origin: center;
`;

export const FinalColWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;
