import type { IconAlignment, InputProps } from "./Input";
import styled, { css } from "styled-components";
import { transition } from "@styles";
import {
  componentInputBdrHover,
  componentInputBg,
  fontWeightSofiaProRegular,
  semanticAlertRedBg,
  semanticBdrFocus,
  semanticBdrNormal,
  semanticGrayBgDisabled,
  semanticTextDisabled,
  semanticTextNormal,
  shadowInputsHoverActive,
} from "@tokens";

const fullWidth = css`
  width: 100%;
`;

export const inputBoxStyles = css<InputProps>`
  background-color: ${componentInputBg};
  border: 1px solid
    ${({ hasError }) => (hasError ? semanticAlertRedBg : semanticBdrNormal)};
  box-sizing: border-box;
  border-radius: 4px;
  ${transition(["border-color", "box-shadow"], ["box-shadow"])}
  ${({ fullWidth: fw = true }) => fw && fullWidth}
`;

export const inputContainerStyle = css<InputProps>`
  padding: 0.9375rem;
  ${inputBoxStyles}
`;

export const inputContainerHoverStyle = css<Pick<InputProps, "hasError">>`
  box-shadow: ${shadowInputsHoverActive};
  ${({ hasError }) => !hasError && `border-color: ${componentInputBdrHover}`};
`;

export const inputContainerDisabledStyle = css`
  cursor: not-allowed;
  background-color: ${semanticGrayBgDisabled};
  border-color: ${semanticBdrNormal};
`;

export const inputContainerFocusStyle = css<Pick<InputProps, "hasError">>`
  outline: none;
  box-shadow: ${shadowInputsHoverActive};
  ${({ hasError }) => !hasError && `border-color: ${semanticBdrFocus}`};
`;

export const inputTextStyle = css`
  font-weight: ${fontWeightSofiaProRegular};
  font-size: 1rem;
  line-height: 1.5;
  color: ${semanticTextNormal};
`;

export const inputTextDisabledStyle = css`
  color: ${semanticTextDisabled};
`;

export const inputPlaceholderStyle = css`
  color: ${semanticTextDisabled};
  padding-left: 0.25rem;
`;

export const inputStyles = css<InputProps>`
  ${inputTextStyle}
  ${inputContainerStyle}

  &[disabled] {
    ${inputTextDisabledStyle}
    ${inputContainerDisabledStyle}
  }

  &:not([disabled]) {
    ${({ emulateFocus }) => emulateFocus && inputContainerFocusStyle}

    &:hover {
      ${({ emulateFocus }) => !emulateFocus && inputContainerHoverStyle}
    }

    &:focus {
      ${inputContainerFocusStyle}
    }
  }

  ::placeholder {
    ${inputPlaceholderStyle}
  }
`;

export const InputBox = styled.span<InputProps & { inline?: boolean }>`
  position: relative;
  display: ${({ inline = true }) => (inline ? "inline-flex" : "flex")};
  ${inputTextStyle}
  ${inputBoxStyles}

  ${({ disabled }) => disabled && inputTextDisabledStyle}
  ${({ disabled }) => disabled && inputContainerDisabledStyle}
  ${({ disabled, emulateFocus }) =>
    !disabled && emulateFocus && inputContainerFocusStyle}

  &:hover {
    ${({ disabled }) => !disabled && inputContainerHoverStyle}
  }

  &:focus-within {
    ${({ disabled }) => !disabled && inputContainerFocusStyle}
  }
`;

export const InnerInput = styled.input<
  InputProps & { alignment: IconAlignment }
>`
  width: 100%;
  background: none;
  border: none;
  padding: ${({ alignment }) =>
    alignment === "right"
      ? "0.9375rem 3.125rem 0.9375rem 0.9375rem"
      : "0.9375rem  0.9375rem 0.9375rem 3.125rem"};

  &:focus {
    outline: none;
  }
  ${inputTextStyle}

  &[disabled] {
    cursor: not-allowed;
    ${inputTextDisabledStyle}
  }

  ::placeholder {
    ${inputPlaceholderStyle}
  }
`;

export const InputIcon = styled.span<{ alignment: IconAlignment }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  ${(props) => (props.alignment === "right" ? "right" : "left")}: 1rem;
  bottom: 0;
  width: 1.25rem;
  display: flex;
  align-items: center;
`;
