import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";

export const PlayIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      ...props.style,
    }}
  >
    <path
      d="M16 0c8.831 0 16 7.169 16 16s-7.169 16-16 16S0 24.831 0 16 7.169 0 16 0Zm6.184 16.566a.664.664 0 0 0 0-1.132l-8.675-5.336a.66.66 0 0 0-1.009.566v10.672a.66.66 0 0 0 1.009.566l8.675-5.336Z"
      fill="currentColor"
    />
  </Icon>
));

PlayIcon.displayName = "PlayIcon";
