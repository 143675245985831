import type { FlatTheme, Theme } from "./Theme";
import { useMemo } from "react";
import { mapTo } from "./utils";

export function flattenTheme(
  { overwrites, extend }: Theme,
  base?: FlatTheme,
): FlatTheme {
  const dark: Record<string, string> = {};
  const light: Record<string, string> = {};

  Object.assign(dark, mapTo(extend?.light || {}, "initial"));
  Object.assign(light, mapTo(extend?.dark || {}, "initial"));

  Object.assign(dark, base?.dark || {});
  Object.assign(light, base?.light || {});

  for (const overwrite of overwrites || []) {
    Object.assign(dark, overwrite.dark);
    Object.assign(light, overwrite.light);
    Object.assign(dark, overwrite.common);
    Object.assign(light, overwrite.common);
  }

  Object.assign(dark, extend?.dark);
  Object.assign(light, extend?.light);
  Object.assign(dark, extend?.common);
  Object.assign(light, extend?.common);

  return { dark, light };
}

export function useFlattenedTheme(
  { overwrites, extend }: Theme,
  base?: FlatTheme,
): FlatTheme {
  return useMemo(
    () => flattenTheme({ overwrites, extend }, base),
    [overwrites, extend, base],
  );
}
