import styled, { createGlobalStyle } from "styled-components";
import {
  boldStyles,
  documentStyles,
  hrStyles,
  monoStyles,
  paragraphStyles,
  semiBoldStyles,
  smallStyles,
  xSmallStyles,
} from "./commonStyles";

/**
 * Attention: Use either `DocumentStyles` or `Body`
 */
export const DocumentStyles = createGlobalStyle<{
  targetElement?: "body" | "html";
}>`
${({ targetElement = "html" }) => targetElement} {
  ${documentStyles}
}
`;
/**
 * Attention: Use either `DocumentStyles` or `Body`
 */
export const Body = styled.body(() => documentStyles);

/* <hr> */

export const Hr = styled.hr.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => hrStyles);

/* <strong> */
export const Bold = styled.strong.withConfig({
  shouldForwardProp: (p) => !["semiBold"].includes(p),
})<{ semiBold?: boolean }>(({ semiBold }) =>
  semiBold ? semiBoldStyles : boldStyles,
);

/* <code> */
export const Code = styled.code(() => monoStyles);

/* <p> */

export const P = styled.p.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => paragraphStyles);

/* <small> */
export const Small = styled.small.withConfig({
  shouldForwardProp: (p) => !["xs"].includes(p),
})<{ xs?: boolean }>(({ xs }) => (xs ? xSmallStyles : smallStyles));
export const XSmall = styled.small(() => xSmallStyles);
