import styled from "styled-components";
import {
  componentSelectBgHover,
  fontSize14,
  fontSize16,
  semanticBdrNormal,
  semanticTextNormal,
} from "@tokens";
import { focusVisible } from "@styles";

export type DropdownMenuItemSize = "normal" | "small";

export const DropdownMenuDivider = styled.hr.attrs(() => ({
  role: "separator",
}))`
  margin: 1rem 0;
  border: none;
  border-top: 1px solid ${semanticBdrNormal};
`;

export const DropdownMenuItem = styled.div<{
  size?: DropdownMenuItemSize;
}>`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${semanticTextNormal};
  font-size: ${({ size = "normal" }) =>
    size === "normal" ? fontSize16 : fontSize14};
  text-align: left;
  border-radius: 0.5rem;
  padding: ${({ size = "normal" }) =>
    size === "normal"
      ? "1.375rem 0.75rem 1.375rem 0.75rem"
      : "0.625rem 0.75rem 0.625rem 0.75rem"};
  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover,
  &:focus-visible {
    background: ${componentSelectBgHover};
  }

  &:active {
    transform: scale(0.985);
  }

  ${focusVisible}
`;
